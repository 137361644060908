import styled from "styled-components/native";

export const StyledContentArea = styled.View`
	display: flex;
    padding: 2rem 0rem 2rem 0rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
	width: 100vw;
`;

// @ts-ignore
const ContentArea = ({ children }) => {
	return (
		// @ts-ignores
		<StyledContentArea>{children}</StyledContentArea>
	);
};

export default ContentArea;
