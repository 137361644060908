import { View, useWindowDimensions, StyleSheet, ActivityIndicator } from "react-native";
import {
	textInputWithButtonsButtonHeight,
	textInputWithButtonsButtonUnderlay,
	textInputWithButtonsButtonWidth,
	textInputWithButtonsFontSize,
} from "../../StyleHelpers";
import { FONT_FAMILY_DEFAULT } from "../../utils/Typography";
import TouchableHighlight from "../TouchableHighlight/TouchableHighlight";
import Text from "../Text/Text";
import ITouchableButtonProps from "../../models/ITouchableButtonProps";
import ComponentThemeType from "../../models/ComponentTheme";
import TouchableButtonVinfast from "./TouchableButtonVinfast";
import H4 from "../Typography/H4";

const TouchableButton = (props: ITouchableButtonProps) => {
	const windowDimensions = useWindowDimensions();

	if (props.componentTheme === ComponentThemeType.VinFast) {
		return (
			<TouchableButtonVinfast {...props}>
				<H4 fontColor={props.fontColor} disabled={props.disabled}>
					{props.children}
				</H4>
				{props.loadingIndicator ? (
					<View style={{ marginLeft: 10}}>
						<ActivityIndicator size={"small"} color={"white"} />
					</View>
				) : <></>}
			</TouchableButtonVinfast>
		);
	}

	return (
		<View>
			<TouchableHighlight
				onPress={props.onPress}
				activeOpacity={1}
				underlayColor={textInputWithButtonsButtonUnderlay}
				windowDimensions={windowDimensions}
				width={textInputWithButtonsButtonWidth}
				height={textInputWithButtonsButtonHeight}
				style={styles.button}
				justifyContent={"center"}
				alignItems={"center"}
				addMarginLeft={props.addMarginLeft}
				addMarginRight={props.addMarginRight}
			>
				<Text windowDimensions={windowDimensions} fontSize={textInputWithButtonsFontSize} style={styles.buttonText}>
					{props.children}
				</Text>
			</TouchableHighlight>
		</View>
	);
};

export default TouchableButton;

const styles = StyleSheet.create({
	button: {
		backgroundColor: "#1e1e1e",
		borderColor: "#3c4043",
		borderWidth: 1,
		borderRadius: 16,
		marginTop: 50,
		marginBottom: 50,
		alignItems: "center",
		justifyContent: "center",
		marginHorizontal: 10,
	},
	buttonText: {
		color: "#FFF",
		fontFamily: FONT_FAMILY_DEFAULT,
		justifyContent: "center",
	},
});
