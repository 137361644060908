import { light_black } from "../../StyleHelpers";
import TopAppbar from "../../components/TopAppBar";
import ridevueLogo from "../../assets/Logo/iconLogo.svg";
import styled from "styled-components/native";
import backIcon from "../../assets/TopNavBarIcon/icon_nav_back.svg";
import closeIcon from "../../assets/TopNavBarIcon/icon_nav_close.svg";
import ImgProgressBar from "./ImgProgressBar";

const StyledContainer = styled.View`
	background-color: ${light_black};
	height: 100vh;
`;

const StyledOptionContainer = styled.View`
	top: 180px;
	height: 60vh;
	margin-left: 190px;
	margin-right: 190px;
`;

const ScreenSelectionContainer = styled.View`
	gap: 24px;
	display: flex;
	flex-direction: row;
	justify-content: center;
`;

const ScreenInnerContainer = styled.View`
	display: flex;
	flex-direction: row;
	gap: 20px;
`;

const ButtonContainer = styled.View`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 48px;
`;

const ButtonWrapper = styled.View`
	overflow: hidden;
	width: 956px;
	height: 72px;
	border-radius: 16px;
`;
const ScreenSelectionBox = styled.View<any>`
	width: 466px;
	height: 424px;
	border-radius: 16px;
	overflow: hidden;
`;


const DemoOptionsLoader = () => {
	return (
		//@ts-ignore
		<StyledContainer>
			<TopAppbar
				leftIcon={closeIcon}
				leftIconWidth={92}
				leftTitleIcon={backIcon}
				screenTitle={""}
				rightLogo={ridevueLogo}
				isSingleLogo={true}
			/>
			{/* @ts-ignore */}
			<StyledOptionContainer>
				{/* @ts-ignore */}
				<ScreenSelectionContainer>
					{/* @ts-ignore */}
					<ScreenInnerContainer>
						<ScreenSelectionBox>
							<ImgProgressBar />
						</ScreenSelectionBox>
						<ScreenSelectionBox>
							<ImgProgressBar />
						</ScreenSelectionBox>
					</ScreenInnerContainer>
				</ScreenSelectionContainer>
				{/* @ts-ignore */}
				<ButtonContainer>
					{/* @ts-ignore */}
					<ButtonWrapper>
						<ImgProgressBar />
					</ButtonWrapper>
				</ButtonContainer>
			</StyledOptionContainer>
		</StyledContainer>
	);
};

export default DemoOptionsLoader;
