import styled, { css } from "styled-components/native";
import ImgProgressBar from "./ImgProgressBar";
import { v4 as uuidv4 } from 'uuid';
import useStyleHelpers from "../../hooks/useStyleHelpers";
import { ScreenDimensions } from "../../utils/enum";

interface Props {
	show?: boolean;
	data?: any;
	hideTitle?: boolean;
	isBanner?: boolean;
	isHeroBanner?: boolean;
	isPlaylist?: boolean;
	isVAM?: boolean;
	isHome?: boolean;
	noPaddingTop?: boolean;
}
interface StyledProps {
	isLandscape?: boolean;
	hideBanner?: boolean;
	hka?: any;
	packshot?: any;
}

const StyledHeroBannerContainer = styled.View<any>`
	margin-bottom: ${(props) => props.rowGap};
	${(props) => props.isNarrow ? `
		display: flex;
		flex-direction: row;
		gap: 1rem;
		justify-content: center;
	` : 'width: 100%'};
`;

const StyledSwiperContainer = styled.View<any>`
	gap: ${(props) => props.rowGap};
`;

const StyledLoadingContainer = styled.View`
	flex-direction: column;
`;

const StyledLoading = styled.View<StyledProps>`
	border-radius: 16px;
	overflow: hidden;

	${(props) =>
		props.isLandscape &&
		css`
			width: ${props?.hka?.width};
			height: ${props?.hka?.height};
		`}
	${(props) =>
		!props.isLandscape &&
		css`
			width: ${props?.packshot?.width};
			height: ${props?.packshot?.height};
		`}
`;

const StyledMainRow = styled.View<any>`
	gap: 0.5rem;
`;

const StyledTitle = styled.View<any>`
	height: ${(props) => props.height}px;
	border-radius: 12px;
	overflow: hidden;
	width: 20vw;
	margin-bottom: 0.5rem;
`;

const StyledHeroBanner = styled.View<any>`
	border-radius: 16px;
	overflow: hidden;
	height: auto;
	aspect-ratio: 4/1;
	margin-right: ${(props) => props.isNarrow ? 0 : props.paddingStart};
	width: ${(props) => props.width};
`;

const StyledLoaderGrid = styled.View<any>`
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	overflow: hidden;
	gap: ${(props) => props.columnGap};
`;

const StyledLoaderContainer = styled.View<any>`
	width: 100vw;
	padding-top: ${(props) => props?.isHome ? `calc(2rem + ${props.paddingTop})` : props.paddingTop};
	padding-left: ${(props) => props.paddingStart};
`;

const CustomizeLoader = (props: Props) => {
	const styleHelper = useStyleHelpers();

	const isNarrow = () => styleHelper?.layout === ScreenDimensions.narrow;

	if (props.show) {
		return (
			//@ts-ignore
			<StyledLoaderContainer paddingStart={styleHelper?.paddingStart} isHome={props?.isHome} paddingTop={props?.noPaddingTop ? 0 : styleHelper?.paddingTop}>
				{props.isBanner || props.isHeroBanner ? (
					// @ts-ignore
					<StyledHeroBannerContainer 
						key={`customize-loader-herobanner-container-${uuidv4()}`} 
						rowGap={styleHelper?.rowGap}
						paddingEnd={styleHelper?.paddingEnd}
						isNarrow={isNarrow()}
					>
						{props.isBanner 
							//@ts-ignore
							? <StyledTitle height={styleHelper?.playlistTitle.lineHeight} key={`customize-loader-herobanner-title-${uuidv4()}`}>
								<ImgProgressBar />
							</StyledTitle> 
							: <></> 
						}
						{/* @ts-ignore */}
						<StyledHeroBanner 
							key={`customize-loader-herobanner-${uuidv4()}`}
							height={styleHelper?.banner.height}
							width={styleHelper?.banner.width}
							paddingStart={props?.isHome ? styleHelper?.columnGap : styleHelper?.paddingStart}
							isNarrow={isNarrow()}
						>
							<ImgProgressBar />
						</StyledHeroBanner>
						{ isNarrow() ?
							<>
								<StyledHeroBanner 
									key={`customize-loader-herobanner-${uuidv4()}`}
									height={styleHelper?.banner.height}
									width={styleHelper?.banner.width}
									paddingStart={styleHelper?.paddingStart}
									isNarrow={isNarrow()}
								>
									<ImgProgressBar />
								</StyledHeroBanner>
								<StyledHeroBanner 
									key={`customize-loader-herobanner-${uuidv4()}`}
									height={styleHelper?.banner.height}
									width={styleHelper?.banner.width}
									paddingStart={styleHelper?.paddingStart}
									isNarrow={isNarrow()}
								>
									<ImgProgressBar />
								</StyledHeroBanner>
							</>
							: <></>
						}
					</StyledHeroBannerContainer>
				) : <></>}
				{props.isPlaylist ? 
					// @ts-ignore
					<StyledSwiperContainer rowGap={styleHelper?.rowGap}>
						{props.data?.map((val: any) => {
							return (
								<StyledMainRow key={`customize-loader-${val.id}`}>
									{/* @ts-ignore  */}
									{!props.hideTitle && (
										// @ts-ignore
										<StyledTitle height={styleHelper?.playlistTitle.lineHeight} key={`customize-loader-title-${val.id}`}>
											<ImgProgressBar />
										</StyledTitle>
									)}
									{/* @ts-ignore  */}
									<StyledLoaderGrid columnGap={styleHelper?.columnGap}>
										{val?.packshot.map((item: any) => {
											return (
												//@ts-ignore
												<StyledLoadingContainer key={`customize-loader-container-${item}`}>
													{/* @ts-ignore */}
													<StyledLoading 
														key={`customize-loader-image-${item}`} 
														isLandscape={val?.isLandscape}
														hka={styleHelper?.hka}
														packshot={styleHelper?.packshot}
													>
														<ImgProgressBar />
													</StyledLoading>
													{props.isVAM && (
													// @ts-ignore
													<StyledTitle isVAM={true} key={`vam-loader-title-${item}`}>
														<ImgProgressBar />
													</StyledTitle>
													)}
												</StyledLoadingContainer>
											);
										})}
									</StyledLoaderGrid>
								</StyledMainRow>
							);
						})}
					</StyledSwiperContainer> : <></>}
			</StyledLoaderContainer>
		);
	}
	return null;
};

export default CustomizeLoader;
