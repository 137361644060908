import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import { Autoplay, Pagination } from "swiper";
import { useState } from "react";
import { white_smoke, dark_gray } from "../../StyleHelpers";
import { StyleSheet } from "react-native";
import TouchableOpacity from "../../components/TouchableOpacity/TouchableOpacity";
import Image from "../../components/Image/Image";
import { calculateImageWidth } from "../../utils/Utils";
import useStyleHelpers from "../../hooks/useStyleHelpers";
import useDimensions from "../../hooks/useDimensions";
import { ScreenDimensions } from "../../utils/enum";
import useGenericContentStore from "../../store/genericContent.store";
import styled from "styled-components/native";

interface Props {
	item: any;
	onPress: (product: any) => void;
}

const StyledMain = styled.View`
	display: flex;
	justify-content: center;
	align-items: center;
`;

const FeatureText = styled.View<any>`
	position: absolute;
	top: 1;
	left: 1;
	display: flex;
	width: 50%;
	height: calc(100% - 2px);
	aspect-ratio: 4/1;
	padding: 2.5rem;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 1.5rem;
	flex-shrink: 0;
	border-radius: 16px;
	border-color: transparent;
	background-image: linear-gradient(90deg, #131313 0%, rgba(19, 19, 19, 0.00) 100%);
	z-index: 9;
`;

const FeatureLineOne = styled.Text<any>`
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: ${(props) => props?.clamp};
	align-self: stretch;
	overflow: hidden;
    text-overflow: ellipsis;
	text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.30);
	font-family: 'Nunito';
	font-size: ${(props) => props?.fontSize};
	font-weight: 500;
	font-style: normal;
	line-height: ${(props) => props?.lineHeight};
	color: ${(props) => props?.hexCode};
`;

const FeatureLineTwo = styled.Text<any>`
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: ${(props) => props?.clamp};
	align-self: stretch;
	overflow: hidden;
	text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.30);
	font-family: 'Nunito';
	font-size: ${(props) => props?.fontSize};
	font-weight: 500;
	font-style: normal;
	line-height: ${(props) => props?.lineHeight};
	color: ${(props) => props?.hexCode};
`;

const TextContainer = styled.View`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 1.5rem;
	align-self: stretch;
`;

const Banner = (props: Props) => {
	const styleHelper = useStyleHelpers();
	const screenDimension = useDimensions();
	const isNarrow = () => screenDimension === ScreenDimensions.narrow;
	const dimensions = useGenericContentStore((state: any) => state.dimensions);
	const [borderEvent, setBorderEvent] = useState(false);

	const calculateItemsByWidth = () => {

		if (!styleHelper?.banner.widthInPixel) return 1.83;

		return (dimensions.window.width / styleHelper?.banner.widthInPixel);
	}

	const convertARGBtoRGBA = (code: any) => {
		const hashtagLessCode = code.replace('#', '');
		const rgb = hashtagLessCode.substring(2);
		const alpha = hashtagLessCode.substring(0, 2);

		return `#${rgb}${alpha}`;
	};

	const renderFeatureText = (obj: any) => {
		if (!obj?.featureText) return <></>;

		const featureText = obj?.featureText?.filter((feature: any) => feature.text);
		const stt = obj?.sttUrl;
		let lineOne: any;
		let lineTwo: any;

		if (!featureText.length && !stt) return <></>;
		
		lineOne = featureText.find((feature: any) => feature.name === 'Line1');
		lineTwo = featureText.find((feature: any) => feature.name === 'Line2');

		return (
			<FeatureText height={styleHelper?.banner.height}>
				{/* @ts-ignore */}
				<TextContainer>
					{stt
						? <Image
								source={{
									uri: `${stt}?width=${calculateImageWidth(false, true)}&disablewebp=false`,
								}}
								resizeMode="contain"
								style={styles.stt}
							/>
						: 	<FeatureLineOne 
								clamp={styleHelper?.banner.lineOne.clamp}
								fontSize={styleHelper?.banner.lineOne.fontSize}
								lineHeight={styleHelper?.banner.lineOne.lineHeight}
								hexCode={convertARGBtoRGBA(lineOne.hexCode)}
							>
								{lineOne.text}
							</FeatureLineOne>
					}
					{lineTwo 
						? <FeatureLineTwo 
							clamp={styleHelper?.banner.lineTwo.clamp}
							fontSize={styleHelper?.banner.lineTwo.fontSize}
							lineHeight={styleHelper?.banner.lineTwo.lineHeight}
							hexCode={convertARGBtoRGBA(lineTwo.hexCode)}
						>
							{lineTwo.text}
						</FeatureLineTwo> 
					: <></>}
				</TextContainer>
			</FeatureText>
		);
	};

	const getHeight = () => isNarrow() ? styleHelper?.banner.height : 'auto';

	return (
		//@ts-ignore
		<StyledMain>
			<Swiper
				style={{ width: '100vw' }}
				slidesPerView={isNarrow() ? calculateItemsByWidth() : 1}
				centeredSlides={isNarrow()}
				spaceBetween={isNarrow() ? 16 : 1}
				pagination={{
					clickable: true,
				}}
				modules={[Autoplay, Pagination]}
			>
				{props.item.isBanner &&
					props.item?.products?.map((product: any, index: number) => {
						return (
							<SwiperSlide key={`swiper-product-${product.parentProductId}`}
								style={{paddingLeft: index === 0 && !isNarrow() ? styleHelper?.paddingStart : ''}} tabIndex={index}
							>
								<TouchableOpacity
									activeOpacity={1}
									onPress={() => props.onPress(product.parentProductId)}
									onMouseEnter={() => setBorderEvent(true)}
									onMouseLeave={() => setBorderEvent(false)}
									style={{height: 'auto', aspectRatio: '4/1', width: styleHelper?.banner.width}}
								>
									{renderFeatureText(product)}
									<Image
										source={{
											uri: `${product.packshots}?width=${calculateImageWidth(false, true)}&disablewebp=false`,
										}}
										
										resizeMode="cover"
										style={[{width: styleHelper?.banner.width, height: getHeight(), aspectRatio: '4/1'}, borderEvent ? styles.onHover : styles.onLeave]}
									/>
								</TouchableOpacity>
							</SwiperSlide>
						);
					})}
			</Swiper>
		</StyledMain>
	);
};

export default Banner;

const styles = StyleSheet.create({
	stt: {
		width: '31.0625rem',
		height: '5.0625rem',
		flexShrink: 0
	},
	onHover: {
		borderRadius: 16,
		outlineStyle: "solid",
		outlineColor: white_smoke,
		outlineWidth: 2,
		outlineOffset: -2,
		transition: "outline-color 0.6s ease",
		backgroundColor: dark_gray
	},
	onLeave: {
		borderRadius: 16,
		outlineStyle: "solid",
		outlineColor: "rgba(255, 255, 255, 0.30)",
		outlineWidth: 2,
		outlineOffset: -2,
		backgroundColor: dark_gray
	},
});
