// @ts-nocheck

import styled, { css } from "styled-components/native";
import { TextInput, MD3DarkTheme as DefaultTheme, configureFonts } from "react-native-paper";
import { FONT_FAMILY_DEFAULT, TEXT_INPUT_FONTSIZE } from "../../utils/Typography";
import { View, Text } from "react-native";
import ITextInputProps from "../../models/ITextInputProps";
import { white_smoke, light_black, gray_one } from "../../StyleHelpers";
import { useRef, useEffect, useState } from "react";
import { globalAny } from "../../utils/Utils";
import useStyleHelpers from "../../hooks/useStyleHelpers";
import { ScreenDimensions } from "../../utils/enum";

interface Props {
	fontSize: number;
}
interface StyledViewProps {
	addLeftSpacing?: boolean;
	addRightSpacing?: boolean;
}

const StyledInput = styled(TextInput)<Props>`
	background-color: ${(props) => (props.disabled ? gray_one : light_black)};
	color: #2362be;
	width: 100%;
	height: 4.5rem;
	font-size: ${(props) => props.fontSize}px;
	font-family: ${FONT_FAMILY_DEFAULT};
	padding-left: 10px;
	flex-grow: 1;
`;

const StyledTextError = styled.Text<Props>`
	color: #ef877f;
	font-family: Nunito;
	font-size: ${(props) => props.fontSize};
	font-style: normal;
	font-weight: 400;
	line-height: ${(props) => props.lineHeight};
	flex: 1 0 0;
`;

const StyledText = styled.Text`
	text-align: right;
	margin-left: 30px;
	font-size: 18px;
	font-family: ${FONT_FAMILY_DEFAULT};
	color: #ffffff;
`;

const StyledView = styled.View<StyledViewProps>`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: -0.625rem;
	align-self: stretch;
`;
const StyledTextErrorContainer = styled.View`
	display: flex;
	height: 1.25rem;
	padding: 0rem 1.5rem;
	align-items: center;
	gap: 0.625rem;
	align-self: stretch;
	flex-direction: row;
`;

const fontConfig = {
	web: {
		light: {
			fontFamily: FONT_FAMILY_DEFAULT,
			fontWeight: 300,
		},
		regular: {
			fontFamily: FONT_FAMILY_DEFAULT,
			fontWeight: 400,
		},
		medium: {
			fontFamily: FONT_FAMILY_DEFAULT,
			fontWeight: 500,
		},
		bold: {
			fontFamily: FONT_FAMILY_DEFAULT,
			fontWeight: 700,
		},
	},
	android: {
		light: {
			fontFamily: FONT_FAMILY_DEFAULT,
			fontWeight: 300,
		},
		regular: {
			fontFamily: FONT_FAMILY_DEFAULT,
			fontWeight: 400,
		},
		medium: {
			fontFamily: FONT_FAMILY_DEFAULT,
			fontWeight: 500,
		},
		bold: {
			fontFamily: FONT_FAMILY_DEFAULT,
			fontWeight: 700,
		},
	},
};

/**
 *
 * Current lib limitation: Change color of label when text input is focused. Read: https://stackoverflow.com/questions/58786281/change-label-color-textinput-react-native-paper-onfocus
 * Specs: https://callstack.github.io/react-native-paper/text-input.html
 */
const TextInputVinfast = (props: ITextInputProps) => {
	const textRef = useRef();
	const [counter, setCounter] = useState(25 - props.value?.length);
	const styleHelper = useStyleHelpers();
	useEffect(() => {
		if (props.autoFocus === true) {
			textRef.current.focus();
		}
	}, []);

	return (
		<StyledView addLeftSpacing={props.addLeftSpacing} addRightSpacing={props.addRightSpacing}>
			{/* @ts-ignore */}
			<StyledInput
				keyboardType={props.keyboardType}
				disabled={props.disabled}
				nativeID={props.nativeID}
				className={props.className}
				mode="outlined"
				activeOutlineColor="#2362be"
				error={props.error}
				autoComplete={false}
				placeholder={props.placeholder}
				secureTextEntry={props.passwordField}
				value={props.value}
				outlineStyle={{ borderRadius: 16, borderWidth: 2 }}
				fontSize={TEXT_INPUT_FONTSIZE}
				label={
					<View style={{ left: -8, backgroundColor: light_black }}>
						<View
							style={{
								fontSize: "1.56rem",
								lineHeight: 60,

								fontWeight: 500,
								marginLeft: 15,
								color: white_smoke,
								fontFamily: FONT_FAMILY_DEFAULT,

								alignItems: "center",
							}}
						>
							<Text>{props.label}</Text>
						</View>
					</View>
				}
				onKeyPress={props?.onKeyPress}
				onChangeText={(text: string) => {
					props?.isCounterLabel && setCounter(25 - text.length);
					props?.onChangeText && props?.onChangeText(props?.capitalizeFirstCharacter ? text.charAt(0).toUpperCase() + text.slice(1) : text);
				}}
				textColor={white_smoke}
				onBlur={props.onBlur}
				right={
					props.inputIcon && (
						<TextInput.Icon
							icon={props.inputIcon}
							onPress={props.onPressInputIcon}
							style={{ marginTop: 15, marginRight: 20 }}
							iconColor={white_smoke}
							size={30}
						/>
					)
				}
				theme={{
					...DefaultTheme,
					fonts: configureFonts({ config: fontConfig, isV3: true }),
					colors: {
						...DefaultTheme.colors,
						error: "#C3443A",
					},
				}}
				onFocus={props.onFocus}
				ref={textRef}
				render={props.render}
				maxLength={props.maxLength}
				onEndEditing={props?.onEndEditing}
				onSubmitEditing={props?.onSubmitEditing}
				onChange={props?.onChange}
				selectTextOnFocus={true}
				// blurOnSubmit={true}
				returnKeyType="done"
			/>

			{/* @ts-ignore */}
			{props?.isCounterLabel || props.error ? (
				<StyledTextErrorContainer>
					{props?.isCounterLabel ? (
						<StyledText>{`${(counter >= 0 && counter) || 0} ${globalAny.language.characters_left}`}</StyledText>
					) : (
						<></>
					)}
					{props.error && (
						<StyledTextError
							isNarrow={styleHelper?.layout === ScreenDimensions.narrow}
							fontSize={styleHelper?.textError?.fontSize}
							lineHeight={styleHelper?.textError?.lineHeight}
						>
							{props.errorMessage}
						</StyledTextError>
					)}
				</StyledTextErrorContainer>
			) : (
				<></>
			)}
		</StyledView>
	);
};

export default TextInputVinfast;
